import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Layout, Wrapper, Header, Content, SectionTitle, FootNote } from '../components';
import config from '../../config/SiteConfig';
import PageProps from '../models/PageProps';

const UpdateUl = styled.ul`
  list-style: none;
`;
export default class NowPage extends React.Component<PageProps> {
  public render() {
    return (
      <Layout>
        <Helmet title={`Now | ${config.siteTitle}`} />
        <Header>
          <Link to="/">{config.siteTitle}</Link>
          <SectionTitle uppercase={true}>What I'm doing now</SectionTitle>
        </Header>
        <Wrapper>
          <Content>
            <h2>I'm living in Tokyo and I'm chasing my goal</h2>
            <p>
              The new normal became normal, I found myself feeling peaceful. So many dreams, so many plans, step by step, I'm gonna go reach
              all of them.
            </p>
            <p>As for my daily life, here're some updates:</p>
            <UpdateUl>
              <li>
                <strong>👫 Living with my dream girl who helps me, guides me, and loves me. 😁</strong>
              </li>
              <li>
                <strong>👨🏻‍💻 Still a tech lead but got promoted twice. 💪</strong>
              </li>
              <li>
                <strong>🦸🏻‍♂️ Trying to be a better person and be more positive.</strong>
              </li>
              <li>
                <strong>🏋️‍♂️ Rope jumping, Pull up, Push up, and Ring Fit Adventure.</strong>
              </li>
              <li>
                <strong>🤯 Dealing with procrastination and anxiety.</strong>
              </li>
            </UpdateUl>
            <FootNote>This "now page" built in the style pioneered by Derek Sivers, which I discovered from Juanito Fatas.</FootNote>
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}
